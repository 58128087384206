// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import ImageUpload from "../components/ImageUpload"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ImageToBase64 = () => (
  <Layout>
    <SEO title="Image to Base64 Converter Online" />
    <ImageUpload/>
   
  </Layout>
)

export default ImageToBase64
